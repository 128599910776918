input[type=text],
input[type=number],
input[type=email],
input[type=search],
input[type=password],
input[type=file],
.react-tags__search-input,
textarea,
select {
    appearance: none;
    min-height: 5rem;
    padding: 0 2rem;
    border: 1px solid $border;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 1.5rem;
    line-height: 1.5;
    padding: 0 1rem;
    margin: 0;
    border-radius: 3px;

    *:disabled {
        background: #e3f1ff;
    }
}

input[type=text]:disabled,
input[type=number]:disabled,
input[type=email]:disabled,
input[type=search]:disabled,
input[type=password]:disabled,
input[type=file]:disabled,
select:disabled {
    background: #e3f1ff;
}

textarea {
    padding: 1rem;
}

.react-tags {
    display: flex;
    flex-direction: column;

    .react-tags__search-input {
        width: 100% !important;
    }

    &__selected {
        margin-top: 1rem;

        &-tag {
            background-color: rgba($blue, .05);
            border: 1px solid rgba($blue, .25);
            color: $blue;
            border-radius: 3px;
            line-height: 2rem;
            padding: .5rem .5rem .5rem 1rem;
            display: inline-flex;
            align-items: center;
            margin: .25rem .5rem .25rem 0;
            cursor: pointer;

            &:after {
                pointer-events: none;
                content: '×';
                line-height: 2.1rem;
                margin-left: 1rem;
                font-weight: bold;
                background-color: rgba($blue, .2);
                border-radius: 4px;
                width: 1.8rem;
                height: 1.8rem;
                color: #FFF;
            }
        }
    }
}

.toggle {
    min-height: 2rem;
    max-height: 2rem;
    min-width: 3.4rem;
    max-width: 3.4rem;
    border-radius: 2rem;
    background-color: #FFF;
    border: 1Px solid $border;
    position: relative;

    &:before {
        position: absolute;
        left: 0;
        top: 0;
        height: 1.5rem;
        width: 1.5rem;
        margin: 0.1rem 0 0 .25rem;
        background-color: $border;
        content:' ';
        border-radius: 1rem;
        transition: all ease-in 200ms;
    }

    &.toggle--active {
        border: 1px solid $green;
        background-color: $green;

        &:before {
            left: 1.2rem;
            background-color: #FFF;
        }
    }
}

select {
    padding: 0 2rem;
}

.form-group {
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    width: 100%;
    margin-bottom: 1rem;

    label {
        font-size: 1.3rem;
        display: flex;
        width: 100%;
        margin-bottom: .5rem;
    }

    sup {
        color: $red;
    }

    small {
        font-size: 1.25rem;
        line-height: 2rem;
        opacity: 1;
        color: #777;

        &.form-errortext {
            color: $red;
            white-space: initial;
        }
        &.form-warningtext {
            color: $orange;
        }
        &.form-infotext {
            color: $blue;
        }
    }

    .prepend,
    .append {
        background-color: $border;
        border-radius: 4px 0 0 4px;
        display: flex;
        align-items: center;
        padding: 0 2rem;
    }

    .append {
        border-radius: 0 4px 4Px 0;
    }

    &--toggle {

        .form-group-content {
            display: flex;
            flex-direction: row;
            align-self: center;

            &__inner {
                display: flex;
                flex-direction: column;
            }
        }

        label {
            font-size: inherit;
            margin: 0;
            align-items: center;

            input {
                margin-right: 1rem;
            }
        }

        img {
            margin: 0 2rem 0 1rem;
            height: 3rem;
        }
    }

    &--selectable {
        border: 1px solid $border;
        width: calc( (100% - 3rem) / 4 );
        padding: 1rem;
        cursor: pointer;
        border-radius: 3px;
        margin: 0 1rem 1rem 0;

        &:nth-child(4n) {
            margin-right: 0;
        }

        label {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: 0;
            cursor: pointer;
            font-size: 1.1rem;
        }

        img {
            width: 40%;
            margin-bottom: 1rem;
        }

        input {
            display: none;
        }

        &__holder {
            display: flex;
            flex-wrap: wrap;
        }

        &--active {
            border: 1px solid $blue;

            font-weight: bold;
        }
    }

    &--disabled {
        opacity: 0.5;
        cursor: not-allowed;

        label {
            cursor: not-allowed;
        }
    }

    .react-autosuggest__container {
        position: relative;

        input {
            width: 100%;
        }

        .react-autosuggest__suggestions-container {
            display: none;
            position: absolute;
            left: 0;
            border: 1px solid $border;
            top: 100%;
            background-color: #FFF;
            width: 100%;

            &--open {
                display: block;
                z-index: 10;
                margin-top: -1px;
            }

            ul {
                list-style: none;
                margin: 0;
                padding: 0;

                li {
                    &.react-autosuggest__suggestion--highlighted {
                        background-color: $blue;
                        color: #FFF;
                    }
                }
            }
        }
    }

    .react-tags {
        &__selected {
            order: 2;
        }

        &__search {
            order: 1;
        }
    }

    &__file {
        border: 1px solid $border;
        display: flex;
        align-items: center;
        padding: 0 1rem;
        border-radius: 3px;
        cursor: pointer;
        position: relative;
        height: 5rem;

        i {
            color: $blue;
            margin-right: 1rem;
        }

        input {
            cursor: pointer;
            opacity: 0;
            position: absolute;
            background: red;
            width: 100%;
            top: 0;
            left: 0;
        }
    }

    &__date {
        border: 1px solid $border;
        padding: 0 1rem;
        height: 5rem;
        font-family: 'Roboto', sans-serif;
    }
}

.form-error {
    color: $red;
    font-size: 1.3rem;
}
