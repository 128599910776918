.tooltip {
    &-inner {
        font-size: 1.25rem;
        padding: .5rem 1rem;
    }
}

.popover {
    &-header {
        font-size: 1.5rem;
    }

    &-body {
        font-size: 1.25rem;
        white-space: nowrap;
    }
}