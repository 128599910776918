table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
    font-size: 1.5rem;

    td,
    th {
        padding: 1.25rem 2rem 1.25rem 0;
        white-space: nowrap;

        &.break-lines {
            white-space: normal;
        }
    }

    td {
        overflow-wrap: break-word;

        img {
            margin-right: 1rem;
        }

        .button {
            margin-left: 1rem;
        }
    }

    &.table--striped {
        th, td {
            border-bottom: 1px solid $border;
        }

        tr {
            &:hover {
                background-color: rgba($border, .25);
            }
        }
    }

    &.table--bordered {
        border: 1px solid $border;

        th, td {
            padding: .75rem 1rem;
        }

        th {
            background-color: rgba($border,.5);
        }

        td {
            border-bottom: 1px solid rgba($border, .5);
            border: 1px solid $border;
            //padding-right: 3rem;
        }

        tr:hover {
            td {
                background-color: rgba($border, .2);
            }
        }
    }
}

.table {
    &__border--right {
        &--white {
            border-right-color: #FFF !important;
        }
    }
}
