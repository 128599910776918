.pricetable {
    display: flex;
    flex-direction: column;
    border-top: 1px solid $border;
    border-bottom: 1px solid $border;
    background-color: rgba(255,255,255,.5);

    &__row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 2rem 2rem;
        border-bottom: 1px dashed $border;

        &:last-child {
            border-bottom: 0;
        }

        span {
            display: flex;
            flex-direction: column;

            &:last-child {
                text-align: right;
            }
        }
    }
}