.app {
    background-color: #F9FAFB;
    display: flex;
    height: 100vh;
    min-width: 1024px;

    &__navbar {
        border-right: 1px solid $border;
        min-width: 10rem;
        max-width: 10rem;
        //background-color: rgba(0, 110, 255, 0.05);
        //background-color: #142f46;
        background-color: rgba($border, .4);
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        padding: .5rem;
        height: 100vh;
        overflow: auto;

        &__logo {
            height: 8rem;
            margin-bottom: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 4rem;
            }
        }

        nav {
            display: flex;
            flex-direction: column;
        }

        &__item {
            width: 100%;
            height: 8rem;
            user-select: none;
            //background-color: $blue;
            display: flex;
            text-decoration: none;
            color: inherit;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            border-radius: .5rem;
            font-size: 1.2rem;
            cursor: pointer;
            transition: background 200ms ease-in;
            //color: #FFF;
            color: #000;

            i {
                font-size: 2.25rem;
                margin-bottom: .75rem;
                opacity: .2;
            }

            .toggle {
                margin: 1rem 0;
                border: 1px solid #CCC;
            }

            &:hover {
                i {
                    //color: $blue;
                    opacity: 1;
                }
            }

            &--active {
                //color: $blue;
                background-color: $blue;
                color: #FFF;

                i {
                    opacity: 1;
                }
            }
        }
    }

    &__sidebar {
        border-right: 1px solid $border;
        min-width: 30rem;
        max-width: 30rem;
        height: 100vh;
        overflow: auto;

        &--right {
            border-left: 1Px solid $border;
            border-right: 0;
            min-width: 38rem;
            max-width: 38rem;
        }

        .title {
            margin-left: 2rem;
        }

        .list__item {
            cursor: pointer;
        }

        .list__description {
            display: flex;
            flex-direction: row;
            color: #333;
            opacity: .7;
            padding: 1rem 2rem;

            span {
                width: 100%;
            }
        }

        .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-left: 2rem;

            .button {
                margin-right: 3rem;
            }
        }
    }

    &__content {
        width: 100%;
        height: 100vh;
        overflow: auto;
        background: #FFF;

        &__header {
            padding: .5rem 1.5rem .5rem 1rem;
            display: flex;
            align-items: center;
            justify-content: space-between;

            &__actions {
                display: flex;
                align-items: center;
            }
        }

        .app__content__header {
            padding: .5rem 2rem;
        }
    }
}
