.dropdown {
  &-toggle {
    outline: none;

    &:after {
      display: none;
    }
  }

  &-menu {
    font-size: 1.5rem;
  }

  &-item {
    text-decoration: none;
    padding: 1rem 2rem;

    img {
      height: 2rem;
      margin-right: 1rem;
    }
  }
}
