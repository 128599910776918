$border: #E7EBF1;
$blue: #006EFF;
$red: #F05A18;
$purple: #3B3B98;
$orange: orange;

.color {
    &--green { color: $green; }
    &--red { color: $red; }
    &--orange { color: $orange; }
    &--blue { color: $blue }
}