.sessionpage {
    background-color: #F9FAFB;
    height: 100vh;
    width: 100%;
    display: flex;
    //align-items: center;
    align-items:flex-start;
    justify-content: center;
    padding: 4rem 2rem;
    overflow: auto;

    &__login {
        max-width: 50rem;
        width: 100%;
        background-color: #FFF;
        padding: 3rem;
        border-radius: 4px;
        box-shadow: 0 2px 4px rgba(0,0,0,0.1);

        h1 {
            display: flex;
            font-size: 2rem;
            margin-bottom: 3rem;
            align-items: center;
            border-bottom: 1px solid $border;
            padding-bottom: 3rem;

            img {
                margin-right: 2rem;
                height: 3rem;
            }
        }

        &__footer {
            text-align: center;
            margin-top: 2rem;
        }
    }
}