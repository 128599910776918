.modal {
    &-backdrop {
    }

    &-body {
        padding: 2rem;
        height: 100%;
        overflow-y: auto;
    }

    &-dialog {
        height: 100vh;
        max-width: 100%;
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .modal.fade & {
            transform: translateY(0px) translateX(50px);
        }
        .modal.show & {
            transform: translateY(0px) translateX(0px);
        }
    }

    &-content {
        border: 0;
        width: 50%;
        max-width: 100rem;
        height: 100%;
        border-radius: 0;
        box-shadow: 0 4px 20px rgba(0,0,0,.2);
    }

    &-footer {
        padding: 2rem;
        justify-content: space-between;
        font-size: inherit;
    }

    &-title {
        font-size: 2rem;
    }

    &-close {
        appearance: none;
        border: 0;
        font-size: 3rem;
        background-color: rgba(0,0,0,.04);
        height: 4rem;
        width: 4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 2rem;
        cursor: pointer;

        &:before {
            content:'×';
        }
    }

    &-header {
        padding: 2rem;
        align-items: center;

        &-actions {
            display: flex;
            align-items: center;
            
            .button {
                margin-right: 2rem;
            }
        }
    }

    &__illustration {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 2rem;
        text-align: center;

        img {
            height: 15rem;
            margin-bottom: 2rem;
        }
    }

    &--prompt {
        margin: 0 auto;

        .modal {
            &-dialog {
                justify-content: center;
            }
            &-content {
                border-radius: 4px;
                height: auto;
                max-width: 50rem;
            }
        }
    }
}