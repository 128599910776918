.message {
    margin :0 auto;

    h4, h5 {
        margin: 0 0 2rem 0;
        padding: 0;
        font-size: 2.5rem;
        font-weight: 300;
        user-select: none;
    }

    h5 {
        border-top: 1px dashed $border;
        font-size: 1.8rem;
        padding-top: 4rem;
        margin-top: 2rem;
        margin-bottom: 0;
    }

    p {
        user-select: none;
        opacity: .5;
        width: 100%;
        max-width: 600px;
        margin: 0 auto;
    }

    &__actions {
        margin-top: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;

        .button {
            margin: 0 .5rem;
        }
    }

    &--loading {
        width: 100%;
        text-align: center;
        padding: 2rem 0;
        color: #FFF;
        
        &--inline {
            width: auto;
            display: flex;
            align-items: center;
            padding: 0 1rem;

            img {
                height: 1.5rem;
                margin-right: 1rem;
            }
    
        }

        &--overlay {
            position: fixed;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(#FFF,.5);
            z-index: 10000;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                height: 1.5rem;
                margin-right: 1rem;
            }
    
        }
    }

    &--complete {
        text-align: center;
        max-width: 60rem;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        img {
            width: 50%;
            margin-bottom: 2rem;
        }

        &.message--small {
            img {
                width: 8rem;
            }
        }

        h3 {
            margin-bottom: 2rem;
        }

        p {
            margin-bottom: 4rem;
        }
    }

    &--empty {
        padding: 4rem;
        text-align: center;
        width: 70%;
        height: 80%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &--holder {
        position: fixed;
        z-index: 10;
        left: 0;
        bottom: 0;
        padding: 0 1rem 1rem 1rem;
        width: 100%;
        max-width: 35rem;

        .message {
            margin-bottom: .5rem;
            width: 100%;
        }
    }

    &--default,
    &--info,
    &--alert,
    &--warning,
    &--success {
        font-size: 1.4rem;
        opacity: 0;
        @include animated;
        animation-name: fadeInUp;
        border-radius: 2px;
        background-color: #FFF;
        box-shadow: 0 2px 4px rgba($border, .5);
        padding: 2rem;
        border-left: 2px solid $border;
    }

    &--info { border-left: 2px solid $blue; }
    &--alert, &--danger { border-left: 2px solid $red; }
    &--success { border-left: 2px solid $green; }
    &--warning { border-left: 2px solid $orange; }
}