.offer {
    border: 1px solid $border;
    box-shadow: 0 0 4px rgba(0,0,0,.05);
    margin: 1rem 4rem 2rem 4rem;
    background-color: #FFF;
    border-radius: 4px;

    &__table {
        width: 100%;
        font-size: 1.5rem;
        

        small {
            font-size: 1.25rem;
        }

        tr:hover {
            td {
                background-color: #F9F9F9;
            }
        }

        tr:first-child {
            td {
                border-top: 0;
            }
        }
        tr:last-child {
            td {
                border-bottom: 0;
            }
        }

        td {
            border: 1px solid #EEE;
            padding: 1rem 2rem;

            &:last-child {
                width: 5rem;
                border-right: 0;
                padding: 1rem;

                .button {
                    margin: 0;
                }
            }

            &:first-child {
                border-left: 0;
            }
        }

        &__divider {
            border-bottom: 0 !important;
            height: 4rem;
            padding: 0;
        }

        &__level0 {
            border-top: 1px solid #27526e;

            &:first-child {
                border-top: 0;
            }
        }

        &__summary {
            background-color: #F9FAFB;
            border-bottom: 1px solid #E7EBF1;

            td {
                padding: 2rem;
                font-weight: bold;
            }
        }

        &__add {

        }

        &__config__price {
            font-weight: bold;
            text-align: right;
        }

        &__content {
            display: flex;
            align-items: center;
        }

        &__level1,
        &__level2 {
            td {
                background-color: #FDFDFD;
            }
            td:nth-child(1) .material-icons {
                font-size: 2rem;
                color: $blue;
                margin-right: 1rem;
            }
        }

        td:nth-child(3) {
            text-align: right;
        }

        &__level1 {
            font-size: 1.4rem;

            td:nth-child(1) {
                padding-left: 4rem;
                font-weight: 500;
            }
        }
        
        &__level2{
            font-size: 1.3rem;

            td:nth-child(1) {
                padding-left: 7rem;
                
                .material-icons {
                    color: #27526e;
                }
            }
            

        }
        
        &__holder {
            width: 100%;
            overflow: auto;
        }

        .divider {
            border-top: 1px solid #CCC;
            margin: 3rem 0 2rem 0;
            display: block;
            width: 100%;
        }
    }

    &__info {
        background-color: rgba(#FFF,.5);
        padding: 3rem 4rem;
    }

    &__price {
        padding: 3rem 4rem;
        background-color: #F9FAFB;
        border-bottom: 1px solid $border;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__item {
        border-bottom: 1px solid $border;
        padding: 3rem 4rem;

        &:last-child {
            border-bottom: 0;
        }

        header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            > div {
                display: flex;
                flex-direction: column;
            }
        }

        &__check {
            margin-right: 2rem;
            min-width: 2rem;
        }

        &__title {
            width: 100%;
            display: flex;
            flex-direction: column;
            color: inherit;
            text-decoration: none;
        }

        &__total {
            margin-left: 3rem;
        }

        &__price {
            text-align: right;
            white-space: nowrap;
            margin-left: 3rem;
        }

        &__detailstoggle {
            cursor: pointer;
            white-space: nowrap;
            color: $blue;
            text-decoration: underline;
        }
    }

    &__details {
        margin-top: 2rem;
        font-size: 1.25rem;
        padding-left: 4rem;

        table {
            td {
                font-size: 1.25rem;
                padding: 1rem 3rem 1rem 0;
                border-top: 1px solid rgba($border,.5);

                &:last-child {
                    padding-right: 0;
                }
            }
        }

        img {
            height: 2rem;
        }
    }
}

.stock-info {
    display: flex;
    flex-direction: column;
    background-color: #f0f3f6;
    font-size: 1.3rem;
    padding: 6px 10px;
    border: 1px solid #dde3e9;
    border-radius: .4em;
    text-align: center;
    cursor: default;
}
