.configuration {
    display: flex;
    height: calc( 100% - 7.5rem );
    width: 100%;
    border-top: 1px solid $border;
    flex: 1;
    
    &__3D {
        
        width: 100%;
        height: 100%;
        position: relative;

        canvas {
            width: 100%;
            height: 100%;
            outline: none;
        }

        &__actions {
            position: absolute;
            bottom: 0;
            left: 0;
            padding: 2rem;
            width: 100%;

            &__inner {
                display: flex;
                align-items: center;
            }
        }
    }

    &__objects {
        background-color: #F9FAFB;
        border-left: 1px solid $border;
        padding: 2rem;
        min-width: 38rem;
        max-width: 38rem;
        overflow: auto;
        height: 100%;

        &__operations {
            margin-bottom: 2rem;
        }

        &__item {
            background-color: #FFF;
            display: block;
            border: 1px solid $border;
            border-radius: .5rem;
            width: 100%;
            margin-bottom: 2rem;
            box-shadow: 0 2px 4px rgba($border,.5);

            &.active {
                border: 1px solid rgba($blue, 1);
                box-shadow: 0 5px 10px rgba($blue,.1);
            }

            &__collapse {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 3rem;
                height: 3rem;
                cursor: pointer;
                background-color: rgba($border,.5);
                margin-left: -.5rem;
                text-align: center;
                margin-right: 1.5rem;
                border-radius: 4px;
            }

            header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 1rem 1rem 1rem 2rem;

                h3 {
                    font-size: 1.5rem;
                    display: flex;
                    flex-direction: column;

                    small {
                        display: block;
                        font-weight: 400;
                        color: #333;
                    }
                }

                > div {
                    display: flex;
                    align-items: center;
                }
            }
        }

        &__content {
            border-top: 1px solid $border;
            padding: 1rem 2rem;
            //background-color: rgba(#F9FAFB,1);
        }

        &__specs {
            display: flex;
            flex-wrap: wrap;
            margin-top: 1rem;
            margin-bottom: 1rem;
        }

        &__spec {
            padding: 1.25rem 1rem .5rem 1rem;
            width: 33.33%;
            border: 1px solid rgba($border, 1);
            background: #FFF;
            border-right: 0;
            display: flex;
            flex-direction: column;
            margin-bottom: -1px;

            &:nth-child(3n+3),
            &:last-child {
                border-right: 1px solid rgba($border, 1);
            }

            span {
                display: block;
                font-size: 1.5rem;
                line-height: 2rem;
                padding: .5rem;
            }

            label {
                line-height: 1rem;
                opacity: .5;
                font-size: 1rem;
                //padding-left: .5rem;
                text-transform: uppercase;
            }

            &__w-100 {
                width: 100%;
            }
        }

        &__actions {
            background-color: rgba(#F9FAFB,.5);
            border-top: 1px solid $border;
            display: flex;
            //padding: 0 0 1rem 1rem;

            &__item {
                background-color: #FFF;
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                border-right: 1px solid $border;
                padding: 1.5rem 1rem 1rem 1rem;
                font-size: 1.1rem;
                cursor: pointer;
                //box-shadow: 0 2px 4px $border;
                border-radius: 4px;
                //margin: 1rem 1rem 0 0;

                &:last-child {
                    border: 0;
                }

                img {
                    width: 2rem;
                    margin-bottom: .75rem;
                    //filter: grayscale(100%);
                    //opacity: .5;
                }

                &:hover {
                    color: $blue;

                    img {
                        opacity: 1;
                        filter: grayscale(0);
                    }
                }
            }
        }
    }
}
