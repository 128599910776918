.shop {
    &__category {
        //background-color: #F9FAFB;
        //border-bottom: 1px solid $border;
        padding: 4rem;

        h2 {
            font-weight: 300;
            margin-bottom: 2rem;
        }

        p {
            opacity: .5;
        }

        &__photos {
            display: flex;
            padding: 3rem 0 0 0;

            a {
                margin-right: 1rem;
                width: 12%;
            }
            img {
                width: 100%;
                height: auto;
                border-radius: .5rem;
            }
        }
    }
}