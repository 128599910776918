.catalogus {
    &__item {
        user-select: none;
        cursor: pointer;
        text-align: center;
        margin-bottom: 4rem;
        text-decoration: none;
        color: inherit;

        img {
            width: 100%;
            margin-bottom: 1rem;
            border-radius: 4px;
            box-shadow: 0 4px 8px rgba(0,0,0,0.2);
        }
    }
}