.landingpage {
    background-color: #F9FAFB;
    width: 100%;
    height: 100%;

    h2 {
        font-size: 3rem;
        margin-bottom: 1rem;
    }

    .property {
        padding: 8rem 0;

        &__item {
            margin-bottom: 4rem;

            img {
                margin-bottom: 2rem;
            }

            h3 {
                color: #27526E;
                font-size: 2rem;
                margin-bottom: 1rem;
            }

            p, ul {
                padding-right: 2rem;
                font-size: 1.75rem;
                line-height: 3rem;
            }

            ul {
                padding-left: 20px;
            }
        }
    }

    .hero {
        text-align: center;
        padding: 2rem 0;
        margin: 4rem 0;
        background-image:url('../assets/img/landingpage/hero-pattern.jpg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        &__title {
            padding: 4rem 0 0 0;
            text-align: center;

            h1 {
                font-size: 6rem;
            }
    
            p {
                font-size: 2rem;
            }
        }

        &__video {
            position: relative;
            width: 90%;
            margin: 4rem auto;
            padding-bottom: 50%;
            overflow: hidden;
            border-radius: 4px;
        }

        img {
            position:absolute;
            top:50%;
            left:50%;
            transform: translate(-50%, -50%);
            width:auto;
            height:100%;
        }

        iframe {
            position:absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
        }
    }

    footer {
        border-top: 1px solid #EEE;
        padding: 4rem;
    }

    header {
        padding: 2rem 0;

        .header {
            &__holder {
                display: flex;
                justify-content: space-between;

                @include media-breakpoint-down(md) {
                    flex-direction: column;
                }
            }

            &__logo {
                font-weight: bold;
                font-size: 2rem;

                img {
                    height: 5rem;
                    margin-right: 2rem;
                }

                @include media-breakpoint-down(md) {
                    text-align: center;
                    font-size: 1.5rem;

                    img {
                        height: 3rem;
                        margin-right: 1rem;
                    }
                }
            }

            &__nav {
                .button {
                    margin-left: 2rem;
                }

                @include media-breakpoint-down(md) {
                    margin-top: 2rem;

                    .button {
                        margin: 0 .5rem;
                    }
                }
            }
        }
    }

    &__steps {
        display: flex;
        align-items: flex-start;
        justify-content: space-around;
        list-style: none;
        margin: 8rem 0;
        padding: 0;
        position: relative;
        counter-reset: steps;

        @include media-breakpoint-down(md) {
            flex-direction: column;
        }

        &:before {
            content:' ';
            border-bottom: 1px dashed $blue;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            margin-top: 2rem;

            @include media-breakpoint-down(md) {
                border-left: 1px dashed $blue;
                border-bottom: 0;
                width: 1px;
                height: 100%;
                margin: 0 0 0 3rem;
            }
        }

        li {
            margin: 0 2rem;
            position: relative;
            padding-top: 6rem;
            counter-increment: steps;

            @include media-breakpoint-down(md) {
                padding: 0 0 0 5rem;
                height: 5rem;
                display: flex;
                align-items: center;
            }

            a {
                text-decoration: none;
                color: #000;

                &:hover {
                    text-decoration: underline;
                }
            }

            &:before {
                content: counter(steps);
                position: absolute;
                left: 50%;
                margin-left: -2rem;
                top: 0;
                width: 4rem;
                height: 4rem;
                border-radius: 2rem;
                text-align: center;
                line-height: 4rem;
                font-weight: bold;
                color: #FFF;
                background-color: $blue;

                @include media-breakpoint-down(md) {
                    margin-left: -1rem;
                    margin-top: .5rem;
                    left: 0;
                }
            }
        }
    }

    .featured {

        &__item {
            padding: 6rem 0;

            &:nth-child(odd) {
                background-color: #FFF;
            }
        }
        &__content {

            h3 {
                padding-right: 2rem;
                color: #27526E;
                font-weight: bold;
                font-size: 3rem;
                margin-bottom: 2rem;
            }

            p {
                padding-right: 2rem;
                font-size: 1.75rem;
                line-height: 3rem;
            }
        }

        &__image {
            text-align: center;
            img {
                width: 70%;
            }
        }

        @include media-breakpoint-down(lg) {
            &__image {
                order: 1;

                img {
                    margin-bottom: 2rem;
                }
            }
            &__content {
                order: 2;
            }
        }
    }
}
