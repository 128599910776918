.nowrap {
    white-space: nowrap;
}

.pointer {
  cursor: pointer;
}

.w {
  &-25 { width: 25%; }
  &-50 { width: 50%; }
  &-75 { width: 75%; }
  &-100 { width: 100%; }
}

/* Borders */
.border {
  &-b { border-bottom: 1px solid $border; }
}

.d-flex { display: flex; }
.flex-column { flex-direction: column;}
.align-items-center { align-items: center; }
.align-items-bottom { align-items: flex-end; }
.space-between { justify-content: space-between; }
.justify-center { justify-content: right}

$viewports: lg, md, sm, xs;
$directions: top, bottom, left, right;
$directions_short: t, b, l, r;
$sizes: 0, 1, 2, 3, 4, 5, 6, 7, 8;

.m {
  @for $s from 1 through length($sizes) {
    &-#{nth($sizes, $s)} {
      margin: #{nth($sizes, $s)}rem;
    }
  }

  @for $s from 1 through length($sizes) {
    &-#{nth($sizes, $s)}-i {
      margin: #{nth($sizes, $s)}rem !important;
    }
  }

  @for $i from 1 through length($directions) {
    @for $s from 1 through length($sizes) {
      &-#{nth($directions_short, $i)}-#{nth($sizes, $s)} {
        margin-#{nth($directions, $i)}: #{nth($sizes, $s)}rem;
      }
    }
  }
}
  
  /*
  Paddings
  .p-t-1 {
      padding-top: 1rem;
  */
  
.p {
  @for $s from 1 through length($sizes) {
    &-#{nth($sizes, $s)} {
      padding: #{nth($sizes, $s)}rem;
    }
  }

  @for $i from 1 through length($directions) {
    @for $s from 1 through length($sizes) {
      &-#{nth($directions_short, $i)}-#{nth($sizes, $s)} {
        padding-#{nth($directions, $i)}: #{nth($sizes, $s)}rem;
      }
    }
  }
}
