.list {
    &__item {
        padding: 1.5rem 2rem 1.5rem 2rem;
        display: flex;
        align-items: center;
        text-align: left;
        user-select: none;
        text-decoration: none;
        color: inherit;

        &--bordered {
            border: 1px solid $border;
            margin-bottom: -1px;
        }

        &--small {
            padding: 1rem 2rem;
        }

        &__content {
            width: 100%;
            display: flex;
            flex-direction: column;

            &__specs {
                display: flex;
                align-items: center;
                margin-top: 1rem;

                img {
                    margin: 0 1rem 0 0 !important;
                }
            }

            &--blue {
                color: $blue;
            }
        }

        &__check,
        &__icon {
            display: flex;
            min-width: 3rem;
            max-width: 3rem;
            text-align: center;
            margin-right: 1rem;
            justify-content: center;

            img {
                width: 1.75rem;
            }

            i {
                color: $blue;
                font-size: 2rem;
            }
        }

        &--operation {
            padding: 1rem 2rem;

            span {
                width: 100%;
            }

            small {
                white-space: nowrap;
            }
        }

        &--offer,
        &--order,
        &--config,
        &--add, {
            cursor: pointer;

            &:before {
                font-family: 'Material Icons';
                min-width: 3rem;
                text-align: center;
                margin-right: 1rem;
                font-size: 2rem;
            }
        }

        &--offer { &:before { content:'description'; color: $blue; } }
        &--order { &:before { content:'local_shipping'; color: $purple; } }
        &--add { &:before { content:'add'; color: $blue; } }

        &--filter,
        &--operation,
        &--config {
            cursor: pointer;
        }

        &--config {
            align-items: flex-start;
            border-bottom: 1px solid rgba($border,.5);

            img {
                height: 2rem;
            }
        }

        &:hover {
            //background-color: rgba(0,0,0,.05);
            background-color: $blue;
            color: #FFF;

            &:before,
            i {
                color: #FFF;
            }

            &.list__item--operation {
                background-color: rgba($blue,.05);
                color: inherit;

                i {
                    color: inherit;
                }
            }

            .list__item__content--subtext {
                color: #FFF;
                font-weight: bold;
            }

            .list__item__content--blue {
                color: #FFF;
            }

        }

        &--active {
            position: relative;
            //background-color: rgba($blue,.1);
            background-color: #FFF;
            //color: #FFF;

            span {
                font-weight: bold;
            }

            &:after {
                content:' ';
                left: 0;
                top: 0;
                width: 2px;
                background-color: $blue;
                height: 100%;
                position: absolute;
            }

            /*&:before {
                display: none;
                content:' ';
                width: 1px;
                height: 100%;
                left: 0;
                top: 0;
                position: absolute;
                background-color: $blue;
            }*/

            &:before {
                color: #FFF;
            }
        }
    }

    &__controls {
        .row {
            margin: 0;
        }

        select {
            height: 5rem;
            min-height: 5rem;
            width: 100%;
        }

        .form-group {
            margin-bottom: 0;
            height: 5rem;
        }
    }
}
