@mixin animated {
    animation-duration: 500ms;
    animation-fill-mode: both;
}

@keyframes fadeInUp {
    from {
        transform: translate3d(0,40px,0)
    }

    to {
        transform: translate3d(0,0,0);
        opacity: 1
    }
}