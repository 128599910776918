.button {
    padding: 0 2.5rem;
    font-weight: 600;
    border-radius: 3px;
    height: 5rem;
    font-size: 1.5rem;
    align-items: center;
    display: inline-flex;
    transition: background 200ms ease-in-out, color 200ms ease-in-out;
    text-decoration: none;
    justify-content: center;
    border: 0;
    margin: 0;
    cursor: pointer;
    user-select: none;
    color: #333;
    background-color: rgba(0,0,0,.04);
    appearance: none;

    &--small {
        height: 3.5rem;
        padding: 0 1.75rem;
        font-size: 1.25rem;

        i {
            font-size: 1.5rem;
        }
    }

    i {
        font-size: 2rem;
    }

    &--icon-before {
        //padding-left: 1.5rem;
        i {
            margin-left: 0;
            margin-right: 1rem;
        }
    }

    &--icon {
        padding: 0 1.5rem;

        &.button--small {
            padding: 0 .75rem;
        }
        
        i {
            margin: 0;
        }
    }

    &--danger {
        background-color: $red;
        color: #FFF;
        border: 1px solid $red;

        &:hover    { background-color: lighten($red, 5%); }
        &:active   { background-color: darken($red, 5%); }
    }

    &--warning {
        background-color: $orange;
        color: #FFF;
        border: 1px solid $orange;

        &:hover    { background-color: lighten($orange, 5%); }
        &:active   { background-color: darken($orange, 5%); }
    }

    &--success {
        background-color: $green;
        color: #FFF;
        border: 1px solid $green;

        &:hover    { background-color: lighten($green, 5%); }
        &:active   { background-color: darken($green, 5%); }
    }

    &--light {
        background-color: #FFF;
        color: #333 !important;

        i {
            color: #333 !important;
        }
    }

    &--outline {
        background-color: transparent;
        border: 1px solid $border;
        color: #333;
    }

    &--outline-danger {
        background-color: transparent;
        border: 1px solid $red;
        color: $red;
    }
    
    &--primary {
        background-color: $blue;
        color: #FFF;

        &:hover    { background-color: lighten($blue, 5%); }
        &:active   { background-color: darken($blue, 5%); }
    }

    &:disabled,
    &[disabled] {
        cursor: not-allowed;
        opacity: .5;
    }
}

.icon-link {
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    color: #333;
    padding: 0 .5rem;

    &:hover {
        opacity: .75;
    }

    i {
        font-size: 1.75rem;
        color: $blue;
        margin-right: 1rem;
    }
}

.button-group {
    display: flex;
    align-items: center;

    .button {
        border-radius: 0;
        border: 1px solid $border;
        background-color: #FFF;
        margin-right: -1px;
        &:first-child { border-radius: 4px 0 0 4px; }
        &:last-child { border-radius: 0 4px 4px 0; }

        &--active {
            border: 1px solid $blue;
            background-color: $blue;
            color: #FFF;
        }
    }
}
