.timeline {
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
    counter-reset: timelineCounter;

    &__holder {
        border-bottom: 1px solid $border;
        //padding: 0 3rem 4rem 3rem;
        padding-top: 0;
        padding-bottom: 4rem;
    }

    &:before {
        content:' ';
        border-left: 1px dotted #333;
        position: absolute;
        left: 0;
        margin-left: 2rem;
        top: 0;
        width: 1px;
        height: 100%;
        z-index: 0;
    }

    &__item {
        display: flex;
        align-items: center;
        padding: 1rem;
        counter-increment: timelineCounter;

        span {
            width: 100%;
            align-items: center;
            display: flex;
            justify-content: space-between;
        }

        @mixin timelineBefore {
            background-color: #FFF;
            position: relative;
            z-index: 1;
            margin-right: 2rem;
            font-size: 2rem;
            line-height: 2rem;
            height: 2rem;
            min-width: 2rem;
            max-width: 2rem;
            color: $border;
            text-align: center;
        }

        &:before {
            content: 'schedule';
            @include timelineBefore;
            font-family: 'Material Icons';
        }

        &--number:not(.timeline__item--done):before {
            @include timelineBefore;
            font-family: inherit;
            content: counter(timelineCounter);
            font-size: 1.5rem;
            color: #777;
            border-radius: 1rem;
        }

        &--done:before {
            font-family: 'Material Icons';
            content: 'check_circle';
            color: $green;
        }

        &--error:before {
            font-family: 'Material Icons';
            content: 'warning';
            color: $orange;
        }

        &:hover {
            background-color: $blue;
            color: #FFF;

            &:before {
                color: #FFF;
                background-color: transparent;
            }
        }
    }
}