@import "./settings/colors";

.search {
    display: flex;
    align-items: center;
    position: relative;
    border: 1px solid $border;
    border-radius: 3px;
    width: 100%;
    //background-color: rgba(0,0,0,.02);
    background-color: transparent;

    &:before {
        content:'search';
        font-family: 'Material Icons';
        opacity: .25;
        font-size: 2rem;
        min-width: 3rem;
        max-width: 3rem;
        height: 3rem;
        text-align: center;
        position: absolute;
        margin-left: .5rem;
        pointer-events: none;
    }

    input {
        width: 100%;
        background: transparent;
        border: 0;
        padding-left: 4rem;
    }

    .app__sidebar & {

        &:before {
            margin-left: 2rem;
        }
        input {
            padding-left: 6rem;
        }
    }
}
