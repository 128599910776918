.article {
  &__category {
    font-weight: 600;
    text-transform: uppercase;
    margin-top: 2rem;
    margin-bottom: 1rem;

    &__info {
      margin-bottom: 3rem;
    }
  }

  &__sectiontitle {
    padding: 2rem 0;
    font-size: 1.25rem;
    margin: 0 0 1rem 0;
    width: 100%;
    color: #999;
    display: flex;
    white-space: nowrap;
    text-transform: uppercase;
    font-weight: 600;
    align-items: center;

    &:after {
      content: " ";
      width: 100%;
      border-top: 1px solid $border;
      margin-left: 1rem;
    }

    &--main {
      font-size: 1.4rem;
    }

    &--small {
      font-size: 1.2rem;
      color: #b5b5b5;
    }

    span {
      display: flex;
      flex-direction: column;
    }
  }

  &__stockicon {
    text-align: center;

    i {
      font-size: 2rem;
    }
  }

  &__table {
    &__level1 {
      font-size: 1.6rem;
    }

    &__level2 {
      font-size: 1.5rem;
    }

    &__header {
      font-weight: bold;
    }

    img {
      width: 5rem;
    }

    .price {
      display: flex;
      align-items: center;

      i {
        margin-left: 1rem;
        font-size: 1.75rem;
      }
    }

    &-item {
      width: 65%;
    }

    &__icon {
      display: inline-flex;
      align-items: center;

      background: $gray-200;

      padding: 0.5rem;

      border-radius: 0.25rem;

      &:first-child {
        margin-right: 1.5rem;
      }

      i {
        font-size: 20px;
      }

      &--delete {
        &:hover {
          color: red;
          text-underline: none;
        }
      }
    }
  }

  &__order {
    &__summary {
      margin-top: 1rem;
      background-color: $border;
      padding: 2rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
