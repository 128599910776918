.delivery-info {
  display: flex;
  background-color: #f0f3f6;
  font-size: 1.3rem;
  padding: 3px 5px;
  border: 1px solid #dde3e9;
  border-radius: .4em;
  cursor: default;

  &-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: auto;
    text-align: center;
    line-height: 1.4rem;
    padding: 5px 15px;

    span {
      display: block;
    }
  }

  &-status {
    display: flex;
    align-items: center;
    padding: 5px 5px;

    i {
      color: $red;
    }
  }

  &-divider {
    border-right: 1px solid #c9cbcc;
    margin: 5px 0;
  }
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5rem 0;

  * {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
}

.order__table {

  &--row:hover {
    background-color: $gray-400;
  }

  &--handled {
    justify-content: flex-start;
  }

  &--statuses {
    justify-content: flex-start;
    align-items: center;
    margin-right: 0.5rem;
  }

}
.order-status {
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;

  &__content {
    display: flex;
    align-items: center;
  }

  span {
    white-space: nowrap;

    i {
      font-size: 1.5em;
      color: $blue;
      display: inline;
    }
  }
}

.stock {
  &--badge {
    display: flex;
    align-items: center;
  }
}

.badge {
  background-color: $gray-400;
  font-size: 1em;
  font-weight: bold;
  color: white;
  border-radius: .4em;
  padding: 3px 12px;

  &--green {
    background-color: $green;
  }

  &--red {
    background-color: $red;
  }

  &--blue {
    background-color: $blue;
  }

  &--orange {
    background-color: $orange;
  }

  &--opacity-1 {
    opacity: 1;
  }
}
