@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');
@import '~material-icons/iconfont/material-icons.css';

/* Bootstrap required files */
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "./node_modules/bootstrap/scss/mixins";

/* Include what ya need */
@import "./node_modules/bootstrap/scss/utilities/text";
@import "./node_modules/bootstrap/scss/dropdown";
@import "./node_modules/bootstrap/scss/tooltip";
@import "./node_modules/bootstrap/scss/grid";
@import "./node_modules/bootstrap/scss/modal";
@import "./node_modules/bootstrap/scss/popover";

/*
@include media-breakpoint-down(xs) { ... }
@include media-breakpoint-down(sm) { ... }
@include media-breakpoint-down(md) { ... }
@include media-breakpoint-down(lg) { ... }
*/

@import "settings/reset";
@import "settings/colors";
@import "settings/typo";
@import "settings/helpers";
@import "settings/animations";

@import "components/buttons";
@import "components/dropdown";
@import "components/tooltip";
@import "components/modal";

@import "app";
@import "articles";
@import "settings";
@import "sessions";
@import "catalogus";
@import "configuration";
@import "forms";
@import "list";
@import "messages";
@import "offer";
@import "order";
@import "pricetable";
@import "search";
@import "shop";
@import "table";
@import "timeline";

@import "landingpage";

* {
  margin: 0;
  padding: 0;
}

html {
    box-sizing: border-box;
    -webkit-text-size-adjust: 100%;
    //font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-family: 'Roboto', sans-serif;
    font-size: 9px;
    line-height: 1.5;
    min-height: 100%;
    color: #000;
    scroll-behavior: smooth;

    /*@include media-breakpoint-down(md) {
        font-size: 7px;
    }

    @include media-breakpoint-down(sm) {
        font-size: 6px;
    }*/
}

body {
    font-size: 1.5rem;
    font-family: 'Roboto', sans-serif;
}

small {
    opacity: .5;
}

img {
    vertical-align: middle;
}

::selection {
    color: #FFF;
    background: $blue;
}

.container-fluid {
    max-width: 1300px;
    margin: 0 auto;
    padding-left: 40px;
    padding-right: 40px;
}

.title {
    padding: 2rem 0;
    font-weight: bold;
    font-size: 1.6rem;
    user-select: none;
    display: flex;
    align-items: center;

    i {
        font-size: 2rem;
        margin-right: 1rem;
    }
}

.sectiontitle {
    padding: 2rem 0;
    font-size: 1.25rem;
    margin: 0 0 1rem 0;
    width: 100%;
    color: #999;
    display: flex;
    align-items: center;
    white-space: nowrap;
    text-transform: uppercase;
    font-weight: 600;

    &:after {
        content:' ';
        width: 100%;
        border-top: 1px solid $border;
        margin-left: 1rem;
    }
}

[contenteditable]:active {
    background: red;
}
[contenteditable]:focus {
    background: #EEE;
    outline: none;
    border-radius: 4px;
}